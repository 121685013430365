@import "fonts";

$border-radius: 2px;
$color-text: #2B323A;
$primary-color: #2B323A;
$border-color: #A6A6A6;

html, body, #root {
  font-family: $font-family !important;
  font-size: $font-size !important;
  font-weight: $font-weight !important;
  color: $color-text !important;
  height: 100%;
  font-style: normal;
}

h1 {
  font-family: $font-family2;
  font-size: 32px;
  font-weight: $font-weight !important;
  text-align: center;
}

h3 {
  font-family: $font-family2;
  font-size: $font-size !important;
  font-weight: $font-weight !important;
  margin: 10px 0;
}

.page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100% !important;
  height: auto !important;
  background-color: #F0F8FF;
}

.container {
  margin-top: 50px;
  margin-bottom: 20px;
  max-width: 530px;
  align-self: center;
}

.form {
  height: 100%;
  max-width: 530px;
  position: relative;
  z-index: 1;
  &__header {
    //text-align: center;
    margin-bottom: 30px;

    .header {
      &__subtext {
        padding: 0 40px;
        text-align: center;
      }
    }
  }

  &__container {
    padding: 40px;
    background: #FFFFFF;
    box-shadow: -1px 4px 10px rgba(0, 0, 0, 0.13);
    border-radius: 5px;
    position: relative;

    .question {
      color: #6c757d;
      font-size: 12px;
      text-align: center;

      a {
        color: #6c757d;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }

      }
    }
  }

  &__footer {
    margin-top: 25px;
    background: #AAEDC5;
    box-shadow: -1px 4px 10px rgba(0, 0, 0, 0.13);
    border-radius: 4px;
    padding: 20px 40px;
  }
}

.img {
  &__wrapper {
    position: relative;

    .img-left-man {
      position: absolute;
      bottom: -470px;
      left: -340px;
    }
    .img-salon {
      position: absolute;
      bottom: -350px;
      left: -90px;
    }

    .img-people {
      position: absolute;
      bottom: -350px;
      right: -90px;

      &.common {
        right: 162px !important;
      }
    }
  }
}

.header {
  &__wrapper {
    height: 65px;
    width: 100%;
    border-bottom: 3px solid #008E9A;
    display: flex;
    align-items: center;
    background-color: #fff;
  }

  &__logo {
    padding-left: 130px;
  }
}

.footer {
  &__wrapper {
    height: 350px;
    width: 100%;
    background: url("../images/promo_block_bg.svg") 50% 100% no-repeat;
  }

  &__fake-block {
    height: 100px;
    width: 100%;
    background-color: #2B323A;
  }
}

.element {
  &__wrapper {
    margin-bottom: 35px;
  }
}

.rating {
  margin-bottom: 0;

  &__wrapper {
    margin-bottom: 25px;
  }

  .p-rating-icon {
    &:focus {
      box-shadow: none;
    }

    &.pi-star {

      &:before {

        content: '';
        display: block;
        width: 30px;
        height: 30px;
        background-position-x: -1px;
        background-image: url("data:image/svg+xml,%3Csvg width='32' height='29' viewBox='0 0 32 29' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.1543 0L20.2171 10.4081L31.3712 11.0557L22.728 18.1359L25.5589 28.9443L16.1543 22.912L6.74973 28.9443L9.58059 18.1359L0.937392 11.0557L12.0915 10.4081L16.1543 0Z' fill='%23F6BA28'/%3E%3C/svg%3E");
      }
    }

    &:before {

      content: '';
      display: block;
      width: 30px;
      height: 30px;
      background-image: url("data:image/svg+xml,%3Csvg width='31' height='29' viewBox='0 0 31 29' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.6152 0L19.678 10.4081L30.8321 11.0557L22.1889 18.1359L25.0198 28.9443L15.6152 22.912L6.21067 28.9443L9.04153 18.1359L0.39833 11.0557L11.5525 10.4081L15.6152 0Z' fill='%23C4C4C4'/%3E%3C/svg%3E");
    }
  }
}

.thrid {
  .spacer {
    height: 25px;
  }

  .rating {
    margin-bottom: 0 !important;
  }
}

.inputtext {
  &__wrapper {
    margin-top: 10px;

    input {
      border: 1px solid #9C9C9C;
      padding: 5px 8px;
      width: 50%;
      font-size: 14px;
      line-height: 18px;

      &:focus {
        outline: none !important;
      }

    }
  }
}

.textarea {
  &__wrapper {
    border: 1px solid #9C9C9C;

    &.error {
      border-color: #c55469;
    }

    textarea {
      border-width: 0;
      width: 100%;
      min-height: 80px;
      padding: 5px 8px;
      font-size: 14px;
      line-height: 18px;
      resize: none;

      &:focus {
        outline: none !important;
      }
    }
  }
}

.nps {
  width: 220px;

  &__wrapper {
    margin-bottom: 20px;
  }

  &__legend {
    display: flex;
    margin-top: 7px;

    span {
      color: #9C9C9C;
      font-size: 10px;
      text-transform: uppercase;
      white-space: nowrap;

      &.dotted {
        margin: 0 10px;
        overflow: hidden;
      }
    }
  }

  .p-selectbutton {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .p-button {
      width: 28px;
      height: 28px;
      border: 1px solid #7A7A7A;
      background-color: transparent;
      border-radius: 2px !important;
      color: #373947;
      align-items: center;
      line-height: 28px;
      //padding: 4px 0 0 0;
      display: flex;
      justify-content: center;

      &:focus {
        box-shadow: none;
      }

      &:not(.p-disabled):not(.p-highlight):hover {
        background-color: transparent;
        border: 1px solid #008E9A;
        color: #373947;
      }

      &.p-highlight {
        background-color: #008E9A !important;
        border: 1px solid #008E9A !important;
        color: #FFFFFF !important;

      }
    }
  }
}

.external-review {
  &__wrapper {

  }

  &__subtext {
    font-size: 12px;
    margin: 20px 0 30px 0;
  }

  .p-selectbutton, .adplatforms {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .p-button, .item {
      width: calc(50% - 5px);
      max-width: 135px;
      height: 58px;
      line-height: 32px;
      border: 1px solid #7A7A7A;
      border-radius: 6px !important;
      background-color: transparent;
      color: #373947;
      padding: 0;
      margin-bottom: 15px;
      display: flex;
      align-content: center;
      justify-content: center;

      &:focus {
        box-shadow: none;
        background-color: transparent;
        border: 1px solid #008E9A;
        color: #373947;

      }

      &:not(.p-disabled):not(.p-highlight):hover {
        background-color: transparent;
        border: 1px solid #008E9A;
        color: #373947;
      }

      &.p-highlight {
        background-color: transparent;
        border: 1px solid #008E9A;
        color: #373947;
      }
    }
  }

}


.variants {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  list-style: none;

  .item {
    width: calc(50% - 5px);
    //width: 100%;
    height: 58px;
    border: 1px solid #7A7A7A;
    border-radius: 6px !important;
    background-color: transparent;
    color: #373947;
    padding: 0;
    margin-bottom: 20px;
    display: flex;
    align-content: center;
    justify-content: center;
    cursor: pointer;
    align-items: center;

    &:focus {
      box-shadow: none;
      background-color: transparent;
      border: 1px solid #008E9A;
      color: #373947;
    }

    &:hover {
      background-color: transparent;
      border: 1px solid #008E9A;
      color: #373947;
    }

    &.p-highlight {
      background-color: transparent;
      border: 1px solid #008E9A;
      color: #373947;
    }
  }
}

.button {
  &__wrapper {
    margin-bottom: 5px;

    .p-button {
      font-family: $font-family !important;
      background-color: #008E9A;
      border: 1px solid #008E9A;
      font-size: 14px;
      border-radius: 2px;
      line-height: 40px;
      width: 245px;
      height: 40px;

      &.btn-outlined {
        border: 1px solid #fff !important;

        &:hover {
          background-color: #fff !important;
          color: #008E9A !important;
        }
      }

      input::placeholder {
        color: #2B323A;
      }

      &:hover {
        background-color: #01B3C2 !important;
        border: 1px solid #01B3C2 !important;
      }
    }
  }
}

.loader {
  width: 100%;
  height: 100%;
  background: #373947;
  position: absolute;
  opacity: 0.7;
  left: 0;
  top: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;

  .p-progress-spinner-circle {
    stroke: #008E9A !important;
  }
}

.agreement {
  .errors {
    &__wrapper {
      padding-bottom: 7px;
    }
  }

  .checkbox {
    &__wrapper {
      display: flex;

      .p-checkbox {
        width: 14px;
        height: 14px;

        &.error {

          .p-checkbox-box {
            border-color: #c55469;
          }
        }

        .p-checkbox-box {
          width: 14px;
          height: 14px;
          font-size: 12px;
          top: 2px;
        }
      }
    }
  }

}

.errors {
  &__wrapper {
    padding-top: 4px;
    font-size: 10px;
    color: #c55469;
  }
}

.pi-check::before {
  content: "\e909";
  font-size: 9px;
  position: relative;
  top: -2px;
}

body .p-checkbox .p-checkbox-box.p-highlight {
  background-color: #9C9C9C;
  border: 1.5px solid #9C9C9C;
  box-sizing: border-box;
  width: 14px;
  height: 14px;
}

body .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
  border-color: #9C9C9C;
  background-color: #9C9C9C;
  color: #ffffff;
}

body .p-checkbox .p-checkbox-box:not(.p-disabled).p-focus {
  box-shadow: none !important;
  border: 0.5px solid #9C9C9C !important;
}

label {

  display: block;
  font-size: 10px;
  color: #9C9C9C;
  margin-left: 9px;

  a {
    color: #9C9C9C;
  }
}

.ico__gis {
  background: transparent url("../images/2gis_ico.png") 50% 50% no-repeat;
  width: 99px;
  height: 23px;
}

.ico__docdoc {
  background: transparent url("../images/docdoc_ico.png") 50% 50% no-repeat;
  width: 99px;
  height: 23px;
}

.ico__website {
  background: transparent url("../images/website_ico.svg") 50% 50% no-repeat;
  width: 99px;
  height: 23px;
}


.ico__yandex {
  background: transparent url("../images/yandex_ico.png") 50% 50% no-repeat;
  width: 130px;
  height: 22px;
}

.ico__avito {
  background: transparent url("../images/avito_ico.png") 50% 50% no-repeat;
  width: 109px;
  height: 24px;
}

.ico__google {
  background: transparent url("../images/google_ico.svg") 50% 50% no-repeat;
  width: 109px;
  height: 24px;
}

.ico__otzovik {
  background: transparent url("../images/otzovik_ico.png") 50% 50% no-repeat;
  width: 104px;
  height: 24px;
}

.ico__flamp {
  background: transparent url("../images/flamp_ico.png") 50% 50% no-repeat;
  width: 104px;
  height: 24px;
}

.ico__recomended {
  background: transparent url("../images/recomended_ico.png") 50% 50% no-repeat;
  width: 118px;
  height: 20px;
}

.ico__vk {
  background: transparent url("../images/vk_ico.png") 50% 50% no-repeat;
  background-size: auto;
  width: 104px;
  height: 24px;
}

.ico__yell {
  background: transparent url("../images/yell_ico.svg") 50% 50% no-repeat;
  background-size: 36px 34px;
}

.ico__zoon {
  background: transparent url("../images/zoon_ico.svg") 50% 50% no-repeat;
  background-size: auto;
  width: 20px;
  height: 20px;
}
.ico__prodoctorov {
  background: transparent url("../images/prodoctorov_ico.svg") 50% 50% no-repeat;
  background-size: auto;
  width: 100px;
  height: 20px;
}

.ico__napopravku {
  background: transparent url("../images/napopravku_ico.svg") 50% 50% no-repeat;
  background-size: 36px 34px;
}

.rejectButtonClass {
  display: none;
}
.acceptButtonClass {
  font-family: $font-family !important;
  background-color: #008E9A;
  border: 1px solid #008E9A;
  font-size: 14px;
  border-radius: 2px;
  line-height: 40px;
  width: 245px;
  height: 40px;

  &:hover {
    background-color: #fff !important;
    color: #008E9A !important;
    border-color: #008E9A !important;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }

}

/* lg-размер (<=1199px) */
@media (max-width: 1199px) {
  /* CSS для ширины от 992px до 1199px */
}

/* md-размер (<=991px) */
@media (max-width: 991px) {
  /* CSS для ширины от 768px до 991px */
}

/* sm-размер (<=768px) */
@media (max-width: 767px) {
  .inputtext {
    &__wrapper {
      width: 100%;
      input {
        width: 100%;
        border-radius: 0;
      }
    }
  }
  .header {
    &__text {
      font-size: 26px;
    }
  }

  .common {
    h1 {
      font-size: 28px;
    }
  }
  .img {
    &__wrapper {
      .img-left-man {
        display: none;
      }
      .img-people {
        &.common {
          right: 110px !important;
        }
      }

    }
  }

  .form {
    &__footer {
      .img-salon {
        width: 50%;
        left: -18px;
      }

      .img-people {
        width: 50%;
        right: 12px;
        bottom: -438px;
      }
    }
  }
  .variants {
    flex-direction: column;

    .item {
      width: 100%;
    }
  }

}

/* xs-размер (<=575px) */

@media (max-width: 575px) {

  .container {
    margin: 20px 20px 20px 20px;
  }

  .header {
    &__logo {
      margin: 0 auto;
      padding: 0;
    }
  }

  .form {
    &__header {
      .header {
        &__subtext {
          text-align: center;
          padding: 0;
        }
      }
    }

    &__container {
      padding: 20px;
    }

    &__footer {
      padding: 20px;
    }

  }
  .img {
    &__wrapper {
      .img-salon {
        width: 60%;
        left: -18px;
        bottom: -350px;
      }

      .img-people {
        width: 60%;
        right: 12px;
        bottom: -380px;
      }

    }
  }
  /* CSS для ширины до 575px (включительно) */
}

@media (max-width: 320px) {
  .external-review {
    .p-selectbutton {
      .p-button {
        width: 115px;
        height: 45px;
      }
    }
  }


}
